<template>
  <b-form-group
    label="Durum"
    label-for="id_com_interview_status"
  >
    <validation-provider
      #default="{ errors }"
      name="Durum"
      rules="required"
    >
      <v-select
        id="id_com_interview_status"
        v-model="interview.id_com_interview_status"
        :options="interviewStatuses"
        :reduce="item => item.id"
        label="title"
        placeholder="Seçiniz"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import { required } from '@validations'
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'InterviewStatuses',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    interview() {
      return this.$store.getters['insuranceInterviewBatch/dataItem']
    },
    interviewStatuses() {
      return this.$store.getters['interviewStatuses/getInterview_statuses']
    },
  },
  created() {
    localize('tr')
  },
  mounted() {
    this.getInterviewStatuses()
  },
  methods: {
    getInterviewStatuses() {
      this.$store.dispatch('interviewStatuses/interview_statusesList', {
        select: [
          'com_interview_status.id AS id',
          'com_interview_status.title AS title',
        ],
        where: {
          'com_interview_status.id_com_interview_type': 4,
          'com_interview_status.hidden': null,
        },
      })
    },
  },
}
</script>

<style scoped>

</style>
