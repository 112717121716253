<template>
  <b-row>
    <b-col
      cols="12"
      md="4"
    >
      <interview-subjects />
    </b-col>
    <b-col
      cols="12"
      md="4"
    >
      <users />
    </b-col>
    <b-col
      cols="12"
      md="4"
    >
      <interview-statuses />
    </b-col>
    <b-col
      cols="12"
      md="12"
    >
      <date-time />
    </b-col>
    <b-col cols="12">
      <interview-content />
    </b-col>
    <b-col>
      <b-form-textarea
        id="chasisList"
        v-model="chasisList"
        rows="20"
        placeholder="Şase Listesi"
      />
    </b-col>
    <b-col>
      <vue-perfect-scrollbar
        class="scroll-area"
        :settings="settings"
      >
        <b-list-group>
          <b-list-group-item class="text-primary font-weight-bold">
            Şase Listesi Önizleme
          </b-list-group-item>
          <b-list-group-item
            v-for="(item,key) in interview.chasis"
            :key="key"
          >
            {{ item }}
          </b-list-group-item>
        </b-list-group>
      </vue-perfect-scrollbar>
    </b-col>
    <b-col
      cols="12"
      class="mt-2"
    >
      <b-alert
        variant="warning"
        show
      >
        <div class="alert-body">
          <p>Tüm alanların eksiksiz doldurulduğundan emin olunuz.</p>
          <p>Şase no listesinde satır boşluğu bırakmadığınızdan ve hatasız yazıldığından emin olunuz.</p>
          <p>Kaydetme işlemi listenin büyüklüğüne göre zaman alabilir kaydetme işlemi bitmeden tarayıcınızı kapatmayınız.</p>
          <p>Yapılan işlem geri alınamaz.</p>
        </div>
      </b-alert>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormTextarea, BAlert, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import InterviewSubjects from '@/views/Insurance/InterviewBatch/forms/InterviewSubjects.vue'
import Users from '@/views/Insurance/InterviewBatch/forms/Users.vue'
import InterviewStatuses from '@/views/Insurance/InterviewBatch/forms/Interview_statuses.vue'
import DateTime from '@/views/Insurance/InterviewBatch/forms/DateTime.vue'
import InterviewContent from '@/views/Insurance/InterviewBatch/forms/Content.vue'

export default {
  name: 'ItemForm',
  components: {
    BRow,
    BCol,
    BFormTextarea,
    BAlert,
    BListGroup,
    BListGroupItem,
    VuePerfectScrollbar,
    InterviewSubjects,
    Users,
    InterviewStatuses,
    DateTime,
    InterviewContent,
  },
  data() {
    return {
      chasisList: null,
      settings: {
        maxScrollbarLength: 60,
      },
    }
  },
  computed: {
    interview() {
      return this.$store.getters['insuranceInterviewBatch/dataItem']
    },
  },
  watch: {
    chasisList(val) {
      if (val) {
        this.interview.chasis = this.chasisList.split('\n')
      } else {
        this.interview.chasis = []
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.scroll-area {
  position: relative;
  margin: auto;
  width: 100%;
  height: 475px;
}
</style>
