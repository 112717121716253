<template>
  <b-form-group
    label="Temsilci"
    label-for="id_com_user"
  >
    <validation-provider
      #default="{ errors }"
      name="Temsilci"
      rules="required"
    >
      <v-select
        id="id_com_user"
        v-model="interview.id_com_user"
        :options="users"
        :reduce="item => item.id"
        label="title"
        placeholder="Seçiniz"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import { required } from '@validations'
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Meets',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    interview() {
      return this.$store.getters['insuranceInterviewBatch/dataItem']
    },
    users() {
      return this.$store.getters['users/getUsers']
    },
  },
  created() {
    localize('tr')
    this.getUsers()
  },
  methods: {
    getUsers() {
      this.$store.dispatch('users/usersList', {
        select: [
          'com_user.id AS id',
          'com_user.name AS title',
        ],
        where: {
          'com_user.status': 1,
          'com_user.id_com_department': 7,
        },
      })
    },
  },
}
</script>
