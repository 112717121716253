<template>
  <validation-observer ref="simpleRules">
    <b-row>
      <b-col cols="12">
        <b-card title="Müşteri Kartı">
          <b-list-group>
            <b-list-group-item v-if="customer.name">
              <div class="font-weight-bold text-primary">
                Müşteri Adı
              </div>
              <div>{{ customer.name }}</div>
            </b-list-group-item>
            <b-list-group-item v-if="customer.company_name">
              <div class="font-weight-bold text-primary">
                Firma Adı
              </div>
              <div>{{ customer.company_name }}</div>
            </b-list-group-item>
            <b-list-group-item v-if="customer.phone">
              <div class="font-weight-bold text-primary">
                Telefon
              </div>
              <div>{{ customer.phone }}</div>
            </b-list-group-item>
            <b-list-group-item v-if="customer.email">
              <div class="font-weight-bold text-primary">
                E-Posta
              </div>
              <div>{{ customer.email }}</div>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card title="Görüşme Notu">
          <item-form />
        </b-card>
        <save-button
          :save-action="saveData"
          :is-disabled="submitStatus"
        />
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import {
  BCard, BRow, BCol, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import ItemForm from '@/views/Insurance/InterviewBatch/ItemForm.vue'
import SaveButton from '@/layouts/components/common/SaveButton.vue'

export default {
  name: 'Add',
  components: {
    BCard,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    ItemForm,
    SaveButton,
    ValidationObserver,
  },
  data() {
    return {
      required,
      submitStatus: false,
    }
  },
  computed: {
    customer() {
      return this.$store.getters['customers/getCustomer']
    },
    interview() {
      return this.$store.getters['insuranceInterviewBatch/dataItem']
    },
    dataSaveStatus() {
      return this.$store.getters['insuranceInterviewBatch/dataSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
    },
  },
  created() {
    localize('tr')
    this.getCustomer()
  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    getCustomer() {
      this.$store.dispatch('customers/customerView', this.$route.params.id)
    },
    saveData() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.submitStatus = true
          this.interview.id_com_customer = this.$route.params.id
          this.$store.dispatch('insuranceInterviewBatch/saveData', this.interview)
            .then(res => {
              if (res) {
                this.submitStatus = false
              }
            })
        }
      })
    },
  },
}
</script>
